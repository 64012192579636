module.exports = {
  // Message //TODO: edit message and defination for every API related.
  api: {
    default: 'システムエラーが発生しました',
    appLogic: {
      errorAppLogicApi: 'システムエラーが発生しました',
    },
    dashboard: {
      successUpdatedCustomerHistory: '顧客履歴を更新しました',
      errorUpdatedCustomerHistory: '顧客履歴が更新できませんでした',
      errorInquiryApi: 'システムエラーが発生しました',
      errorMonthlyInquiryApi: 'システムエラーが発生しました',
      errorCustomerHistoryInChargeApi: 'システムエラーが発生しました',
      errorUpdatedCustomerHistoryApi: 'システムエラーが発生しました',
      errorDashBoardApi: 'システムエラーが発生しました',
    },
    response: {
      successCreatedResponse: '反響を作成しました',
      successUpdatedResponse: '反響を更新しました',
      successDeletedResponse: '反響を削除しました',
      errorDeletedResponse: '反響を作成しました',
      errorUpdatedResponse: '反響が更新できませんでした',
      errorInput: '入力項目をご確認ください',
      errorResponseApi: 'システムエラーが発生しました',
      errorMonthlyInquiryApi: 'システムエラーが発生しました',
      errorInquiryTypeApi: 'システムエラーが発生しました',
      errorCustomerApi: 'システムエラーが発生しました',
      errorMediumApi: 'システムエラーが発生しました',
      errorCustomerHistoryApi: 'システムエラーが発生しました',
    },
    customer: {
      successCreatedCustomer: '顧客を作成しました',
      successUpdatedCustomer: '顧客を更新しました',
      successDeletedCustomer: '顧客を更新しました',
      successAddedCustomerAction: 'アクションを追加しました',
      successUpdatedCustomerHistory: '顧客履歴を更新しました',
      successDeletedCustomerHistory: '顧客履歴を削除しました',
      successCreatedCustomerTasya: '他社提案物件を追加',
      errorAddedCustomerAction: 'アクションが追加できませんでした',
      errorUpdatedCustomerHistory: '顧客履歴が更新できませんでした',
      errorUpdatedIsReadHistory: '顧客履歴が更新できませんでした',
      errorDeletedCustomerHistory: '顧客履歴が削除できませんでした',
      errorCreatedCustomerHistory: 'システムエラーが発生しました',
      errorCreatedCustomer: 'システムエラーが発生しました',
      errorUpdatedCustomer: '顧客が更新できませんでした',
      errorDeletedCustomer: 'システムエラーが発生しました',
      errorInput: '入力項目をご確認ください',
      errorCustomerApi: 'システムエラーが発生しました',
      errorCustomerHistoryApi: '顧客履歴が取得できませんでした',
      errorCustomerProjectApi: 'システムエラーが発生しました',
      errorCustomerAgreementApi: 'システムエラーが発生しました',
      errorCustomerResponseApi: 'システムエラーが発生しました',
      errorSideCustomerHistoryApi: 'システムエラーが発生しました',
      errorCreatedCustomerTasya: '顧客他社提案物件を追加できませんでした',
    },
    project: {
      successCreatedProject: '案件を作成しました',
      successUpdatedProject: '案件を更新しました',
      successDeletedProject: '案件を削除しました',
      errorCreatedProject: 'システムエラーが発生しました',
      errorUpdatedProject: 'システムエラーが発生しました',
      errorDeletedProject: 'システムエラーが発生しました',
      errorMonthlyProject: 'システムエラーが発生しました',
      errorProjectApi: 'システムエラーが発生しました',
      errorSideProjectApi: 'システムエラーが発生しました',
      errorInput: '入力項目をご確認ください',
    },
    agreement: {
      successUpdatedAgreement: '成約を更新しました',
      successCreatedAgreement: '成約を作成しました',
      successDeletedAgreement: '成約情報を削除しました',
      errorDeletedAgreement: '成約を作成しました',
      errorCreatedAgreement: 'システムエラーが発生しました',
      errorUpdatedAgreement: '成約が更新できませんでした',
      errorAgreementApi: 'システムエラーが発生しました',
      errorMonthlyAgreementApi: 'システムエラーが発生しました',
      errorInput: '入力項目をご確認ください',
    },
    media: {
      successCreatedMedia: '媒体を作成しました',
      successUpdatedMedia: '媒体を更新しました',
      successDeletedMedia: '媒体を削除しました',
      errorCreatedMedia: '媒体が作成できませんでした',
      errorUpdatedMedia: '媒体が更新できませんでした',
      errorDeletedMedia: '共通媒体は削除できません',
      errorDeletedMediaWithEcho: '共通媒体は削除できません',
      errorMediaApi: 'システムエラーが発生しました',
      errorInput: '',
    },
    clientHistory: {
      errorClientHistoryApi: 'システムエラーが発生しました',
      errorCreatedClientReceiveMail: 'システムエラーが発生しました',
    },
    mailTemplate: {
      successCreatedMailTemplate: 'メールテンプレートを作成しました',
      successUpdatedMailTemplate: 'メールテンプレートを更新しました',
      successDeletedMailTemplate: '選択したメールテンプレートを削除しました',
      errorCreatedMailTemplate: 'メールテンプレートが作成できませんでした',
      errorUpdatedMailTemplate: 'メールテンプレートが更新できませんでした',
      errorDeletedMailTemplate: '共通メールテンプレートは削除できません',
      errorDeletedMediaWithEcho: '共通メールテンプレートは削除できません',
      errorMailTemplateApi: 'システムエラーが発生しました',
    },
    mail: {
      successCreatedMail: 'メールを作成しました',
      successUpsertMailDraft: '下書きとして保存しました',
      successUpdatedMail: 'メールを更新しました',
      successDeletedMail: '選択したメールを削除しました',
      successSendMailTest: 'テストメールが送信されました。',
      successUpdateMailSetting: '設定を更新しました',
      errorCreatedMail: 'メールが作成できませんでした',
      errorUpdatedMail: 'メールが更新できませんでした',
      errorDeletedMail: '共通メールは削除できません',
      errorDeletedMailWithEcho: '共通メールは削除できません',
      errorMailApi: 'システムエラーが発生しました',
      errorConfirmMail: '現在回線が混み合っています。お手数ですが、時間を空けて再度送信ください。',
      errorInput: '入力項目をご確認ください',
      processingFailed: '処理に失敗しました',
    },
    line: {
      successCreatedLine: 'LINE送信作成を作成しました',
      errorCreatedLine: 'LINE送信作成が作成できませんでした',
      errorInput: '入力項目をご確認ください',
    },
    stepMail: {
      successCreatedStepMail: 'ステップメールを作成しました',
      successUpdatedStepMail: 'ステップメールを更新しました',
      successDeletedStepMail: '選択したステップメールを削除しました',
      errorCreatedStepMail: 'ステップメールが作成できませんでした',
      errorUpdatedStepMail: 'ステップメールが更新できませんでした',
      errorStepMailApi: 'システムエラーが発生しました',
      errorDeletedStepMail: 'システムエラーがは削除できません',
    },
    documentTemplate: {
      successCreatedDocumentTemplate: '書類テンプレートを作成しました',
      successUpdatedDocumentTemplate: '書類テンプレートを更新しました',
      successDeletedDocumentTemplate: '選択した書類テンプレートを削除しました',
      errorDocumentTemplateApi: 'システムエラーが発生しました',
      errorCreatedDocumentTemplate: '書類テンプレートが作成できませんでした',
      errorUpdatedDocumentTemplate: '書類テンプレートが更新できませんでした',
      errorDeletedDocumentTemplate: '共通書類テンプレートは削除できませんでした',
      errorInput: '入力項目をご確認ください',
    },
    staffMessage: {
      successCreatedStaffMessage: 'スタッフメッセージを作成しました',
      errorCreatedStaffMessage: 'スタッフメッセージが作成できませんでした',
      errorConfirmedStaffMessage: 'スタッフメッセージが確認できませんでした',
      errorStaffMessageApi: 'システムエラーが発生しました',
      errorInput: '入力項目をご確認ください',
    },
    propertyRangeTorihikitaiyo: {
      successUpdatedSetting: '不動産エージェント物件公開範囲設定取引態様を更新しました',
      errorSettingApi: 'システムエラーが発生しました',
      errorUpdatedSetting: '不動産エージェント物件公開範囲設定取引態様が更新できませんでした',
      errorInput: '入力項目をご確認ください',
    },
    staff: {
      successCreatedStaff: 'スタッフを作成しました',
      errorCreatedStaff: 'スタッフが作成できませんでした',
      successUpdatedStaff: 'スタッフを更新しました',
      errorUpdatedStaff: 'スタッフが更新できませんでした',
      errorStaffApi: 'システムエラーが発生しました',
      successDeletedStaff: '選択した譜表を削除しました',
      errorInput: '入力項目をご確認ください',
    },
    maisokuTemplate: {
      successCreatedMaisokuTemplate: 'マイソクテンプレートを作成しました',
      successDeletedMaisokuTemplate: '選択したマイソクテンプレートを削除しました',
      successUpdatedMaisokuTemplate: 'マイソクテンプレートを更新しました',
      errorCreatedMaisokuTemplate: 'マイソクテンプレートが作成できませんでした',
      errorDeletedMaisokuTemplate: 'マイソクテンプレートは削除できませんでした',
      errorUpdatedMaisokuTemplate: 'マイソクテンプレートが更新できませんでした',
      errorMaisokuTemplateApi: 'システムエラーが発生しました',
      errorInput: '入力項目をご確認ください',
    },
    maisokuTemplateUserSetting: {
      successUpsertedMaisokuTemplateUserSetting: 'マイソクテンプレートユーザー設定を登録しました',
      errorUpsertedMaisokuTemplateUserSettingMail: 'マイソクテンプレートユーザー設定が登録できませんでした',
      errorMaisokuTemplateUserSettingApi: 'システムエラーが発生しました',
      errorInput: '入力項目をご確認ください',
    },
    maisokuOutput: {
      successCreatedMaisokuOutput: 'マイソク出力を作成しました',
      successUpdatedMaisokuOutput: 'マイソク出力を更新しました',
      successDeletedMaisokuOutput: '選択したマイソク出力を削除しました',
      errorCreatedMaisokuOutput: 'マイソク出力が作成できませんでした',
      errorUpdatedMaisokuOutput: 'マイソク出力が更新できませんでした',
      errorDeletedMaisokuOutput: 'マイソク出力は削除できませんでした',
      errorExportedMaisokuOutput: '物件情報を出力できませんでした',
      errorMaisokuOutputApi: 'システムエラーが発生しました',
      errorInput: '入力項目をご確認ください',
    },
    advertise: {
      successUpsertAdvertise: '広告費用を設定しました',
      errorUpsertAdvertise: '広告費用を設定できません',
      errorAdvertiseApi: 'システムエラーが発生しました',
    },
    activity: {
      successUpsertActivity: '作業活動を設定しました',
      errorUpsertActivity: '作業活動を設定できません',
      errorActivityApi: 'システムエラーが発生しました',
    },
    file: {
      errorReadFileContents: 'ファイルの内容を読み取れません',
      errorValidationFile: 'フォーマットの検証に失敗しました。エラー内容をご確認ください。',
      errorExportFile: 'ファイルの出力に失敗しました。',
      errorFileExportApi: 'システムエラーが発生しました',
      fileSizeLimitExceeded: 'ファイルサイズの上限を超えています',
      fileSizeMailLimitExceeded: 'ファイルサイズの上限（約4.5MB〜5.0MB）を超えています。<br>添付ファイル、画像を選び直してください。',
    },
    csvImport: {
      successCreateCsvImport: 'CSVインポートを作成しました',
      errorImportCsvApi: 'システムエラーが発生しました',
      errorInput: '入力項目をご確認ください',
    },
    achievement: {
      successCreatedAchievement: '業績レポートを作成しました',
      successUpdatedAchievement: '業績レポートを更新しました',
      successDeletedAchievement: '選択した業績レポートを削除しました',
      errorCreatedAchievement: '業績レポートが作成できませんでした',
      errorUpdatedAchievement: '業績レポートが更新できませんでした',
      errorAchievementApi: 'システムエラーが発生しました',
      errorDeletedAchievement: '業績レポートが削除できませんでした',
    },
    tagMaster: {
      successCreate: "タグ情報を登録しました。",
      errorCreate: "タグ情報が登録できませんでした。",
      successDelete: "タグ情報を削除しました。",
      errorDelete: "タグ情報が削除できませんでした。",
      successUpdate: "タグ情報を更新しました。",
      errorUpdate: "タグ情報が更新できませんでした。",
      errorInput: '入力項目をご確認ください',
    },
    announcement: {
      successCreatedAnnouncement: 'お知らせ情報を作成しました',
      successUpdatedAnnouncement: 'お知らせ情報を更新しました',
      successDeletedAnnouncement: '選択したお知らせ情報を削除しました',
      errorCreatedAnnouncement: 'お知らせ情報が作成できませんでした',
      errorUpdatedAnnouncement: 'お知らせ情報が更新できませんでした',
      errorAnnouncementApi: 'システムエラーが発生しました',
      errorInput: '入力項目をご確認ください',
      errorDeletedAnnouncement: 'お知らせ情報が削除できませんでした',
    },
    achievementOutput: {
      successCreatedAchievementOutput: '業績レポートを作成しました。',
      errorCreatedAchievementOutput: '業績レポートを作成できませんでした。',
      errorInput: '入力項目をご確認ください',
      successDeletedAchievementOutput: '業績レポートを削除しました。',
      errorDeletedAchievementOutput: '業績レポートが削除できませんでした。',
      errorAchievementOutputApi: 'システムエラーが発生しました',
    },
    achievementOutputOption: {
      successUpsertAchievementOutputOption: '業績スタッフ設定を更新しました。',
      errorAchievementOutputOptionApi: 'システムエラーが発生しました',
    },
    vendor: {
      errorVendorApi: 'システムエラーが発生しました',
    },
  },
  modal: {
    default: 'システムエラーが発生しました',
    clientAdvancedSearch: {
      errorInput: '町域、小学校区、中学校区、沿線、駅のいずれか一つ以上を選択してください'
    }
  }
};
